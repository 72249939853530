import authAxios from "../../../../helpers/authAxios";
import { Col, Row, Tabs } from 'antd';
import './form.global.scss';
import styles from './itemDetails.module.scss';
import './itemDetailsRetailer.module.scss';
import { useEffect, useState } from 'react';
import { useAppSelector } from '../../../../hooks/useAppSelector';
import { getDescriptorsList } from '../../../../api/filter';
import { useDispatch } from 'react-redux';
import { getCountries } from '../../../../api/filter';
import { createDraftItem, editDraftItem } from '../../../../api/items';
import { useActions } from '../../../../hooks/useActions';
import { getSizes } from '../../../../api/filter/index';
import {
    getRetailerProductByGtin
} from "../../../../api/products";
import { convertFilesForRequest } from "../../../../helpers";
import { useLocation } from "react-router-dom";
import {ProductFieldsDictionary} from "../../../common/constants/products";
import ProductHierarchy from '../../../common/components/ProductHierarchy';
import { useNavigate } from "react-router-dom";
import { number } from "yup";
import noImage from "../../../common/icons/noImage.png"
//import Nutritional from "./nutritional"

interface FormProps {
  element: any;
  dataForm: any;
  setIsMainFieldsFull: any;
  isLastTab: any;
  isDraft: boolean;
}

interface OptionsProps {
  created_at: string;
  id: number;
  item_field_id: number;
  name: string;
  updated_at: string;
}

interface ItemProps {
  created_at: string;
  id: 6;
  is_file: boolean;
  is_size: boolean;
  is_language: boolean;
  is_multiple: boolean;
  is_elabel: boolean;
  item_tab_id: number;
  key: any;
  mandatory: string | null;
  status: boolean;
  updated_at: string;
  name: string;
  field_type_id: number;
  field_select_options: OptionsProps[];
}

export const ItemDetails = ({element, dataForm, setIsMainFieldsFull, isLastTab, isDraft, }: FormProps) => {
    const {activeItem} = useAppSelector((state) => state.activeItems);
    const {activeHierarchy} = useAppSelector((state) => state.activeItems);
    const {photos} = useAppSelector((state) => state.activeItems);
    const item_info = activeItem ? (activeItem.item_info ? activeItem.item_info : activeItem) : {};
    let item_json = item_info !== undefined && item_info.item_json ? JSON.parse(item_info.item_json) : (typeof item_info === 'object' ? item_info : {});
    const dispatch = useDispatch();
    const { descriptorsList, countriesList } = useAppSelector(
        (state) => state.optionLists
    );
    const [hierarchyDescriptors, setHierarchyDescriptors] = useState([]);
    const { itemFormFields } = useAppSelector((state) => state.itemForm);

    const { company_id }: any = useAppSelector((state) => state.itemForm);
    const [formData, setFormData] = useState<any>({
        id: null,
        gtin: null,
        description: null,
        trade_item_descriptor_id: null,
        brand: null,
        target_market_id: null,
      });

    const [formDataError, setFormDataError] = useState<any>({});
    const [dinamicFormData, setDinamicFormData] = useState<any>([]);
    const [dinamicFormDataError, setDinamicFormDataError] = useState<any>([]);
    const [mandatoryDinamicField, setMandatoryDinamicField] = useState({});
    const [mandatoryMetaDataFields, setMandatoryMetaDataFiealds] = useState({});
    const [chosenImage, setChosenImage] = useState(0);
    const [fileUrl, setFileUrl] = useState<any>();
    const { popup }: any = useAppSelector((state) => state.itemForm);
    const { user }: any = useAppSelector((state) => state.auth);
    const selectedTab: any = useAppSelector(
        (state) => state.modals.draftItemsFormModalActiveTab
        );
    const [gtinError, setGtinError] = useState<string>('');
    const [metaData, setMetaData] = useState<any[]>([]);
    const [metaError, setMetaError] = useState<boolean>(false);
    const [newLink, setNewLink] = useState('');
    const [newLinkError, setNewLinkError] = useState(false);
    const { setModalActiveTab } = useActions();
    const [itemAllergens, setItemAllergens] = useState<any[]>([]);
    const [itemNutritionals, setItemNutritionals] = useState<any>([]);
    const { userCompanyType } = useAppSelector((state) => state.companies);
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        if (!metaData) return;
        if (typeof metaData[chosenImage]?.file !== 'string') {
            setFileUrl('');
        }
      }, [chosenImage]);

        useEffect(() => {
            if (!dataForm?.file_links?.length) return;
            const data: any = [];
            dataForm.file_links.forEach((elem: any) => {
              const fieldsObj: any = {};
              elem.fields.forEach((e: any) => {
                fieldsObj[e.item_field.id] = e.value;
            });
              data.push({
                file: elem.file,
                fields: fieldsObj,
            });
          });
            setMetaData(data);
        }, [dataForm]);

        useEffect(() => {
            if (!element) return;

            if (element?.name === 'digital assets') {
              const data: any = {};

              element.items.forEach((elem: any) => {
                if (elem.is_file) {
                  data[elem.id] = '';
              }
          });

              const dataFormObj = { ...formData };
              const result: any = [];
              dataFormObj?.file_links?.forEach((elem: any) => {
                if (elem.fields.length) {
                  const fieldsObj: any = {};
                  elem.fields.forEach((e: any) => {
                    if (e.item_field.id) {
                      fieldsObj[e.item_field.id] = e.value;
                  }
              });
                  result.push({
                    file: elem.file,
                    fields: { ...fieldsObj },
                });
              } else {
                  result.push({
                    file: elem.file,
                    fields: { ...data },
                });
              }
          });
              setMetaData(result);
          }
      }, [element, formData]);

        const handleClear = () => {
            const dinamicData = { ...dinamicFormData };
            element?.items.forEach((elem: any) => {
              dinamicData[elem.key] = null;
          });
            const data = { ...formData };
            if (element?.name === 'main') {
              data.gtin = null;
              data.description = null;
              data.trade_item_descriptor_id = null;
              data.brand = null;
              data.target_market_id = null;
          }
          if (element?.name === 'measurements') {
              data.quantity = null;
          }
          if (element?.name === 'digital assets') {
              data.file_links = [];
          }
          setDinamicFormData(dinamicData);
          setFormData(data);
      };

      useEffect(() => {
        if (!dataForm) {
          setIsMainFieldsFull(false);
      } else {
          setIsMainFieldsFull(true);
      }
    }, [dataForm]);

      useEffect(() => {
        if (!element) return;
        const data: any = {};
        element?.items?.forEach((elem: any) => {
          if (
            elem.mandatory === 'Yes' &&
            +elem.field_type_id !== 2 &&
            !elem.is_file
            ) {
            data[elem.key] = true;
    }
    });
        setMandatoryDinamicField(data);

        const metaData: any = {};
        element?.items?.forEach((elem: any) => {
          if (
            elem.mandatory === 'Yes' &&
            +elem.field_type_id !== 2 &&
            elem.is_file
            ) {
            metaData[elem.id] = true;
    }
    });
        setMandatoryMetaDataFiealds(metaData);
        const languageData: any = { ...dinamicFormData };
        var isLanguageKeys: Array<string> = [];
        element?.items?.forEach((elem: ItemProps) => {
          const item = dataForm?.item_forms?.find((elemDraft: any) => {
            if(elemDraft.item_field !== undefined && elemDraft.item_field !== null) {
              return elemDraft.item_field.key === elem.key  
          }

      });
          languageData[elem.key] = item ? item.value : null;
         //Keep language field keys
            if(item) {
              if(item.item_field.is_language) {
                isLanguageKeys.push(elem.key);  
            }

        }
    });

        languageData['isLanguageKeys'] = isLanguageKeys;
        setDinamicFormData(languageData);
    }, [element]);

    // inserts STATIC values from an open element
      useEffect(() => {
        if (!dataForm || !descriptorsList || !countriesList) return;
        let data: any = { ...formData };
        let dataErr: any = {};
        let dinamicData: any = { ...dinamicFormData };
        data.id = dataForm.id;
        if (element?.name === 'main') {
          data.gtin = dataForm.gtin;
          data.description = dataForm.description;
          const itemDescriptor: any = descriptorsList.find(
            (elem: any) => +elem.value === +dataForm.trade_item_descriptor_id
            );
          data.trade_item_descriptor_id = itemDescriptor
          ? itemDescriptor?.value + ''
          : null;
          data.brand = dataForm.brand;
          const targetMarketId: any = countriesList.find(
            (elem: any) => +elem.value === +dataForm.target_market_id
            );
          data.target_market_id = targetMarketId
          ? targetMarketId?.value + ''
          : null;
      }
      if (element?.name === 'measurements') {
          data.quantity = dataForm.quantity;
          dataErr.quantity = false;
      }
      if (element?.name === 'digital assets') {
          data.file_links = dataForm?.file_links;
      }
      dataForm?.item_forms?.forEach((elem: any) => {
          if(elem.item_field !== undefined && elem.item_field !== null) {
            dinamicData[elem.item_field.key] = elem.value;  
        }

    });

    //const languageData: any = { ...dinamicFormData };
    var isLanguageKeys: Array<string> = [];
    element?.items?.forEach((elem: ItemProps) => {
      const item = dataForm?.item_forms?.find((elemDraft: any) => {
        if(elemDraft.item_field !== undefined && elemDraft.item_field !== null) {
          return elemDraft.item_field.key === elem.key  
        }

      });
      dinamicData[elem.key] = item ? item.value : null;
     //Keep language field keys
        if(item) {
          if(item.item_field.is_language) {
            isLanguageKeys.push(elem.key);  
        }

    }
    });

    dinamicData['isLanguageKeys'] = isLanguageKeys;
    setFormDataError(dataErr);
    setDinamicFormData(dinamicData);
    setFormData(data);

    //Allergens
    if(dataForm.item_allergens !== undefined && dataForm.item_allergens.length > 0) {
      setItemAllergens(dataForm.item_allergens)
    }

    //Nutritional
    if(dataForm?.item_nutrients !== undefined && dataForm?.item_nutrients.length > 0) {
      const nutrients: any = [];
    
      dataForm.item_nutrients.map((value: any) => {
              nutrients[value.nutrientCode] = {
                nutrientName: value.nutrientName, 
                nutrientCode: value.nutrientCode, 
                qtyMeasurementPrecisionCode: value.qtyMeasurementPrecisionCode,
                qtyMeasurementPrecisionValue: value.qtyMeasurementPrecisionValue, 
                unitMeasure: value.unitMeasure, 
                percentMeasurementPrecisionCode: value.percentMeasurementPrecisionCode, 
                percentMeasurementPrecisionValue: value.percentMeasurementPrecisionValue, 
                derivationCode: value.derivationCode
          }
      });
      setItemNutritionals(nutrients);
    }
    
    }, [dataForm, element, countriesList, descriptorsList]);

      useEffect(() => {
        dispatch(getDescriptorsList());
        dispatch(getCountries());
        dispatch(getSizes());
    }, []);

    // inserts DINAMIC values from an open element
      useEffect(() => {
        if (!element || !dataForm) return;
        const data: any = { ...dinamicFormData };
        var isLanguageKeys: Array<string> = [];
        element?.items?.forEach((elem: ItemProps) => {
          const item = dataForm?.item_forms?.find((elemDraft: any) => {
            if(elemDraft.item_field !== undefined && elemDraft.item_field !== null) {
              return elemDraft.item_field.key === elem.key  
          }

      });
          data[elem.key] = item ? item.value : null;
          //Keep language field keys
          if(item) {
              if(item.item_field.is_language) {
                isLanguageKeys.push(elem.key);  
            }

        }
    });
        data['isLanguageKeys'] = isLanguageKeys;
        setDinamicFormData(data);
    }, [element, dataForm]);

      const handleChange = (name: string, value: any) => {
        let data = { ...dinamicFormData };
        data[name] = value;
        setDinamicFormData(data);
    };

    // check if a field is filled at fill time
    useEffect(() => {
        const errors: any = { ...formDataError };
        const errorDinamic = { ...dinamicFormDataError };
        for (let prop in formData) {
          if (formData[prop]) {
            errors[prop] = false;
        }
    }
    for (let prop in mandatoryDinamicField) {
      if (dinamicFormData[prop]) {
        errorDinamic[prop] = false;
      }
    }

    //Set mandatory for Allergens
    if (itemAllergens !== undefined && itemAllergens !== null && itemAllergens.length > 0) {
      errorDinamic['allergen-codes'] = false;
    }

    setDinamicFormDataError(errorDinamic);
    setFormDataError(errors);
    }, [formData, dinamicFormData]);



    useEffect(() => {
        if (!element || !metaData) return;
        if (element.name === 'digital assets') {
          const errorMetaData: any = { ...mandatoryMetaDataFields };
          const data = JSON.parse(JSON.stringify(metaData));
          let isValid = true;
          data.forEach((elem: any) => {
            for (let prop in elem.fields) {
              if (typeof elem.file === 'string') {
                for (let p in errorMetaData) {
                  if (prop === p) {
                    if (!elem.fields[prop]) {
                      isValid = false;
                  }
              }
          }
      }
    }
    });
          if (isValid) {
            setMetaError(false);
        }
    }
    }, [element, metaData]);

    const handleSubmit = () => {
        let isValid = true;
        const errors = { ...formDataError };

        if (element?.name === 'main') {
          if (!/^[0-9]+$/.test(formData.gtin)) {
            isValid = false;
            errors.gtin = true;
        }
        if (!formData.gtin) {
            isValid = false;
            errors.gtin = true;
        }
        if (!formData.description) {
            isValid = false;
            errors.description = true;
        }
        if (!formData.trade_item_descriptor_id) {
            isValid = false;
            errors.trade_item_descriptor_id = true;
        }
        if (!formData.brand) {
            isValid = false;
            errors.brand = true;
        }
        if (!formData.target_market_id) {
            isValid = false;
            errors.target_market_id = true;
        }
    }
    if (element?.name === 'measurements') {
      if (!formData.quantity) {
        isValid = false;
        errors.quantity = true;
    }
    }
    if (element?.name === 'digital assets') {
      if (newLinkError) {
        isValid = false;
    }
    const errorMetaData: any = { ...mandatoryMetaDataFields };
    const data = JSON.parse(JSON.stringify(metaData));
    data.forEach((elem: any) => {
        for (let prop in elem.fields) {
          if (typeof elem.file === 'string') {
            for (let p in errorMetaData) {
              if (prop === p) {
                if (!elem.fields[prop]) {
                  isValid = false;
                  setMetaError(true);
              }
          }
      }
    }
    }
    });
    }
    setFormDataError(errors);

    const errorDinamicData: any = { ...mandatoryDinamicField };
    const errorData = { ...dinamicFormDataError };
    for (let prop in errorDinamicData) {
      if (!dinamicFormData[prop]) {
          isValid = false;
          errorData[prop] = true;
      }
    }
    setDinamicFormDataError(errorData);
    
    if(element?.name == 'Ingredients') {
      const errorDinamicData: any = { ...mandatoryDinamicField };
      const errorData = { ...dinamicFormDataError };

      for (let prop in errorDinamicData) {
        if (!dinamicFormData[prop]) {
          isValid = false;
          errorData[prop] = true;
        } else {
          const languageData = typeof dinamicFormData[prop] === 'string'?JSON.parse(dinamicFormData[prop]):dinamicFormData[prop];
          languageData.map((line) => {
            if(line['languageText'] !== '' && line['languageCode'] === '' || 
              line['languageText'] === '' && line['languageCode'] !== '' ||
              line['languageText'] === '' && line['languageCode'] === '') {
              isValid = false;
              errorData[prop] = true;
            }
          })
        }
      }
      setDinamicFormDataError(errorData);
    }

    if(selectedTab == 'allergen') {
      const errorDinamicData: any = { ...mandatoryDinamicField };
      const errorData = { ...dinamicFormDataError };

      if (itemAllergens !== undefined && itemAllergens !== null && itemAllergens.length == 0) {
        isValid = false;
        errorData['allergen-codes'] = true;
      }

      setDinamicFormDataError(errorData);
    }
    
    if (isValid) {
      const converFormData: any = { ...formData };
      delete converFormData.file_links;
      delete dinamicFormData.isLanguageKeys;

      const requestData = {
        ...converFormData,
        files: convertFilesForRequest(metaData),
        item_forms: JSON.stringify({ ...dinamicFormData }),
        item_allergens: JSON.stringify({ ...itemAllergens }),
        item_nutrients: JSON.stringify({ ...itemNutritionals }),
    };
    if (!dataForm) {
        if (element?.name === 'main') {
          dispatch(createDraftItem(company_id, requestData, selectedTab));
          dispatch(setModalActiveTab("measurements"))
      } else {
          dispatch(
            editDraftItem(company_id, requestData, selectedTab, isLastTab)
          );
      }
    } else {
        dispatch(
          editDraftItem(company_id, requestData, selectedTab, isLastTab)
        );
    }
    }
    // }
    };

    useEffect(() => {
        if (!formData.gtin) return;
        if (!/^[0-9]+$/.test(formData.gtin)) {
          setGtinError('GTIN is not valid');
      } else {
          setGtinError('');
      }
    }, [formData.gtin]);

    const setMetaDatavalues = (id: number, value: any) => {
        if (!fileUrl) return;
        const data = [...metaData];
        const elem = data.find((e: any) => {
          return e.file === fileUrl;
        });

        if(elem !== null && elem !== undefined) {
          elem.fields[id] = value;
        }

        setMetaData(data);
    };

    const getMetaValue = (id: number) => {
        if (!fileUrl) {
          return '';
      } else {
          const data = [...metaData];
          const elem = data.find((e: any) => {
            return e.file === fileUrl;
        });
          return elem?.fields?.[id];
      }
    };

    const setNewLinkFunc = (e: any) => {
        setNewLink(e.target.value);
        let data = JSON.parse(JSON.stringify(metaData));
        data.push({
          file: e.target.value,
          fields: {},
      });

        setMetaData(data);
    };

    useEffect(() => {
        if (newLink.length > 255) {
          setNewLinkError(true);
      } else {
          setNewLinkError(false);
      }
    }, [newLink]);

    useEffect(() => {
        var res = [];
        if(activeHierarchy.length > 0) {
            for(var i in activeHierarchy) {
                res.push({
                    descriptor: activeHierarchy[i].trade_item_descriptor.abbreviation, 
                    gtin: activeHierarchy[i].gtin
                });
            }
        }
        
        setHierarchyDescriptors(res);
    }, [activeHierarchy]);

    const onChangeTab = (key) => {
          dispatch(
              setModalActiveTab(
                key
              )
          );
      
    }

    let i = 0;

    const renderItem = (itemEntry: any, pre: String) => {
        let ret = [];
        i++;
        
        if(Array.isArray(itemEntry)) {
            itemEntry.map((element: any) => {
                ret = ret.concat(renderItemLine(element, pre))
            })
        } else {
            ret = ret.concat(renderItemLine(itemEntry, pre))
        }
        
        return ret;
    }

    const renderItemLine = (itemLine: any, pre: String) => {
        let ret = [];
        i++;
        if(pre === 'flex') {
            pre = ''
        }
        if(pre !== '') {
            pre = pre + '/'
        }
        
        if(Array.isArray(itemLine)) {
            //console.log(itemLine);
        } else {
            //console.log(itemLine);
            Object.entries(itemLine).map((element: any) => {
                if(typeof element[1] === 'string') {
                    let keyName = pre + element[0]
                    ret.push({[keyName] : element[1]})
                } else {
                    if(Array.isArray(element[1])) {
                        ret = ret.concat(renderItem(element[1], pre + element[0]));
                    }
                }
            });
        }
        
        
        return ret;
    }

    const renderItemLine2 = (itemLine: any, pre: String, item: Object, onlyRight?: boolean) => {
        let ret = null;
        if(onlyRight === undefined || onlyRight === null) {
            onlyRight = true
        }
        
        if(item !== undefined && item !== null)
        if(typeof itemLine === 'string' || typeof itemLine === 'number') {
            let value = item[itemLine];
            
            if(typeof value === 'string') {
                ret = <tr style={{verticalAlign: 'top'}}>
                    <td className={styles.td}>
                        <strong>{pre === '' ? renderName(itemLine as string) : renderName(pre as string)}</strong>
                    </td>
                    <td className={styles.td}>
                        {itemLine === 'allergenTypeCode' && allergenCodes[value] !== undefined ?
                            allergenCodes[value] + ` (${value})` : value
                        }
                    </td>
                </tr>
            } else {
                if(value === undefined) {
                    
                    // console.log(item)
                    // ret = item[0]!== undefined && Array.isArray(item) ? renderItemLine2(0, itemLine as string, item[0]):
                    onlyRight || itemLine === 'tradeItemKeyWords' ?
                    ret = <tr style={{verticalAlign: 'top'}}>
                        <td className={styles.td}>
                            <strong>{pre === '' ? renderName(itemLine as string) : renderName(pre as string)}</strong>
                        </td>
                        <td className={styles.td}>
                            { 
                                typeof item === 'object' ?
                                    Object.keys(item).map((value, key) => {
                                        // if(itemLine === 'nutrientInformation') {
                                        //     console.log(Object.keys(item))
                                        // }
                                        return typeof item[key] === 'object' ? 
                                            Object.keys(item[key]).map((value, subkey) => {
                                                return renderItemLine2(value, '', item[key])
                                                //return <>{item[key][value]} <br/></>
                                            })
                                            : <>
                                                {/* {console.log(typeof item[key])}
                                                {console.log(key)} */}
                                                {item[key]} <br/>
                                            </>
                                    }): item 
                                }
                        </td>
                    </tr> 
                    :
                    // console.log(item)
                    ret = 
                        typeof item === 'object' ?
                            Object.keys(item).map((value, key) => {
                                return typeof item[key] === 'object' ? 
                                    Object.keys(item[key]).map((value, subkey) => {
                                        return renderItemLine2(value, '', item[key])
                                        //return <>{item[value][value]} <br/></>
                                    })
                                    : typeof item[value] === 'object' ? 
                                    Object.keys(item[value]).map((key, subvalue) => {
                                        return renderItemLine2(value, '', item[value])
                                        //return <>{item[value][value]} <br/></>
                                    })
                                    :<>
                                        {item[key]} <br/>
                                    </>
                            }): item 
                        
                } else  {
                    if(Array.isArray(value)) {
                        ret = []
                        value.map((arrayValue, key) => {
                            
                            if(typeof key === 'number') {
                                ret.push(renderItemLine2(arrayValue, itemLine as string, value[key]))
                            } else {
                                ret.push(renderItemLine2(arrayValue, itemLine as string, value))
                            }
                        })
                    } else {
                        // if(itemLine === 'flex') {
                        //     console.log(value)
                        //     //console.log(item)
                        // }
                        ret = []
                        Object.keys(value).map((arrayValue, key) => {
                            // if(itemLine === 'flex') {
                            //     console.log(arrayValue)
                            // }
                            ret.push(renderItemLine2(arrayValue, '', value))
                        })
                    }
                }
            }
            
        } else {
            
            if(Array.isArray(itemLine)) {
                ret = []
                itemLine.map((arrayValue, key) => {                    
                    ret.push(renderItemLine2(arrayValue, '', item))
                })
            } else {
                ret = []
                Object.keys(itemLine).map((arrayValue, key) => {                    
                    ret.push(renderItemLine2(arrayValue, '', itemLine))
                })
            }
        }
        
        return ret;
    }


//    const detailItems = renderItem(item_json, '');
    var item_json_copy = Object.keys(item_json);
    if(item_json !== undefined && item_json['flex'] !== undefined) {
        var flexKeys = Object.keys(item_json['flex']);
        for(let i in flexKeys) {
            item_json_copy.push('flex/' + flexKeys[i])
        }
        delete item_json_copy[item_json_copy.indexOf('flex')]
        //console.log(item_json_copy)
    }
    
    const renderName = (text: string) => {
        if(ProductFieldsDictionary[text] !== undefined) {
            return ProductFieldsDictionary[text];
        } else {
            return capitalizeFirstLetter(text.split(/(?=[A-Z])/).join(' '))
        }
    }

    const capitalizeFirstLetter = (text: any) => {
        return text.charAt(0).toUpperCase() + text.slice(1)
    }

    const handlePdfClick = async () => {
        const data = await authAxios(dispatch).get(`/api/retailer/product/${item_json.gtin}/pdf`) as string;
        
        const url = window.URL.createObjectURL(new Blob([data], {type: 'application/pdf'}));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "product.pdf"); //or any other extension
        document.body.appendChild(link);
        link.click();
    }

    const handleHierarcyElementClick = (gtin) => {
        dispatch(getRetailerProductByGtin(gtin));
        navigate(`/items/${gtin}/details`);
    }

    const fieldSections = {
        'main': [
            'gtin',
            'informationProviderGLN',
            'informationProviderName',
            'targetMarket',
            'targetMarketName',
            'productType',
            'gdsnRegistryStatus',
            'gtinName',
            'brandName',
            'shortDescription',
            'functionalName',
            'productDescription',
            'isBaseUnit',
            'isConsumerUnit',
            'isDispatchUnit',
            'isInvoiceUnit',
        ],
        'measurements': [
            'packagingMarkedReturnable',
            'nonGTINPalletHi',
            'nonGTINPalletTi',
            'width',
            'height',
            'depth',
            'volume',
            'grossWeight',
            'netWeight'
        ],
        'packaging' : [
            'flex/packagingInformation',
            'flex/packagingDate'
        ],
        'marketing' : [
            'flex/marketingMessage',
            'flex/tradeItemKeyWords',
            'flex/tradeItemFeatureBenefit'
        ],
        'ingredients' : [
            'flex/ingredientStatement',
        ],
        'NonFoodIngredients': [
            'flex/nonfoodIngredientStatement'
        ],
        'nutrients' : [
            'flex/nutrientInformation'
        ],
        'allergens' : [
            'flex/allergenRelatedInformation'
        ],
        'digitalAssets' : [
            'flex/externalFileLink'
        ]
    }

    const allergenCodes = {
        'AC': 'Crustaceans and their derivates',
        'AE': 'Eggs and their derivates',
        'AF': 'Fish and their derivates',
        'AM': 'Milk and its derivates',
        'AN': 'Tree nuts and their derivatives',
        'AP': 'Peanuts and their derivates',
        'AS': 'Sesame Seeds and their derivates',
        'AU': 'Sulfur Dioxide and Sulfits',
        'AW': 'Cereals and Their Derivatives',
        'AY': 'Soybean and its Derivatives',
        'BC': 'Celery and its Derivatives',
        'BM': 'Mustard and its derivates',
        'NC': 'Cocoa and its derivates',
        'NK': 'Coriander and its derivates',
        'NL': 'Lupine and its derivates',
        'NM': 'Corn and its derivates',
        'NP': 'Pod Fruits and their derivates',
        'NR': 'Rye and Their Derivatives',
        'NW': 'Carrots and their derivates',
        'UM': 'Molluscs and their derivates',
        'UW': 'Wheat and Their Derivatives',
        'AA': 'Amylcinnamyl Alcohol ',
        'AH': 'Anise Alcohol',
        'AI': 'Alpha-Isomethyl Ionone ',
        'AL': 'Amyl Cinnamal ',
        'AX': 'Other Gluten Containing Grain And Gluten Containing Grain Products ',
        'BA': 'Benzyl Alcohol ',
        'BB': 'Benzyl Benzoate ',
        'BE': '2 (4-tert-Butylbenzyl)',
        'BI': 'Benzyl Cinnamate',
        'BS': 'Benzyl Salicylate ',
        'CA': 'Cinnamyl Alcohol ',
        'CL': 'Cinnamal ',
        'CN': 'Citronellol',
        'CO': 'Coumarin ',
        'CT': 'Citral ',
        'EG': 'Eugenol ',
        'EP': 'Oak Moss Extract',
        'EV': 'Treemoss Extract',
        'FA': 'Farnesol ',
        'GB': 'Barley  and Barley Products ',
        'GE': 'Geraniol ',
        'GK': 'Kamut ',
        'GO': 'Oats ',
        'GS': 'Spelt and Spelt Products',
        'HC': 'Hydroxyisohexyl 3-Cyclohexene Carboxaldehyde',
        'HX': 'Hexyl Cinnamaldehyde',
        'HY': 'Hydroxycitronellal ',
        'ML': 'Lactose',
        'NE': 'Peas And Pea Products ',
        'SA': 'Almond And Almond Products ',
        'SC': 'Cashew And Cashew Products ',
        'SH': 'Hazelnut And Hazelnut Products ',
        'SM': 'Macadamia Nut And Macadamia Nut Products ',
        'SP': 'Pecan Nut And Pecan Nut Products ',
        'SQ': 'Queensland Nut and Queensland Nut Products ',
        'SR': 'Brazil Nut And Brazil Nut Products ',
        'ST': 'Pistachio And Pistachio Products ',
        'SW': 'Walnut And Walnut Products ',
        'X99': 'Does Not Contain Declaration Obligatory Allergens ',
        'BF': 'Beef and its derivatives',
        'BN': 'Isoeugenol',
        'BO': 'd-Limonene',
        'BP': 'Linalool',
        'BQ': 'Methyl heptin carbonate',
        'CM': 'Chicken Meat and Their Derivatives',
        'PO': 'Pork and its derivatives',
        'SB': 'Seed Products',
        'SX': 'Pulses',
        'UN': 'Shellfish and its Derivatives',
        'AD': '3-Amino-2,4-dichlorophenol',
        'BR': '1,3-Bis-(2,4-diaminophenoxy)propane',
        'CS': 'Cotton Seeds and Their Derivatives',
        'DA': '2,6-Dimethoxy-3,5-pyridinediamine HCl',
        'DP': 'Diaminophenols',
        'FH': '4-Hydroxy-propylamino-3-nitrophenol',
        'FT': '4-Amino-3-nitrophenol',
        'GL': 'Glutamate and Their Derivatives',
        'HB': 'HC Blue No 12',
        'HD': 'HC Blue No 11',
        'HE': 'Hydroxybenzomorpholine',
        'HH': 'Hydroxypropyl bis(N-hydroxyethyl-p-phenyldiamine) HCl',
        'HN': 'Hydroxyethyl-2-nitro-p-toluidine',
        'HP': '2-Hydroxyethyl-picramic acid',
        'MH': '2-Methyl-5-hydroxyethylaminophenol',
        'ON': '1-Naphthol ',
        'PM': 'p-Methylaminophenol',
        'PN': 'Pine Nut and Their Derivatives',
        'PP': 'p-Phenylenediamine',
        'PS': 'Poppy Seeds and Their Derivatives',
        'SS': 'Sunflower Seeds and Their Derivatives',
        'TA': '3-Aminophenol',
        'TD': 'Toluene-2,5-diamine',
        'TR': 'Triticale and their derivatives',
        'SO': 'Coconuts and Their Derivatives',
        'SD': 'Beech Nuts and Their Derivatives',
        'SE': 'Butternuts and Their Derivatives',
        'SF': 'Chinquapins and Their Derivatives',
        'SG': 'Ginkgo Nuts and Their Derivatives',
        'SI': 'Hickory Nuts and Their Derivatives',
        'SJ': 'Shea Nuts and Their Derivatives',
        'SK': 'Pili Nuts and Their Derivatives',
        'SL': 'Lichee Nuts and Their Derivatives',
        'ABD': 'Tuna and its Derivatives',
        'ABE': 'Walleye and its Derivatives',
        'ABF': 'Barnacle and its Derivatives',
        'ABG': 'Crab and its Derivatives',
        'ABH': 'Crawfish and its Derivatives',
        'ABI': 'Krill and its Derivatives',
        'ABJ': 'Lobster and its Derivatives',
        'ABK': 'Prawns and their Derivatives',
        'ABL': 'Shrimp and its Derivatives',
        'ADB': 'Bass and its Derivatives',
        'ADC': 'Anchovy and its Derivatives',
        'ADE': 'Catfish and its Derivatives',
        'ADF': 'Cod and its Derivatives',
        'ADG': 'Flounder and its Derivatives',
        'ADH': 'Grouper and its Derivatives',
        'ADI': 'Haddock and its Derivatives',
        'ADJ': 'Hake and its Derivatives',
        'ADK': 'Halibut and its Derivatives',
        'ADL': 'Herring and its Derivatives',
        'ADM': 'Mahi mahi and its Derivatives',
        'ADP': 'Pike and its Derivatives',
        'ADQ': 'Pollock and its Derivatives',
        'ADR': 'Salmon and its Derivatives',
        'ADS': 'Snapper and its Derivatives',
        'ADT': 'Sole and its Derivatives',
        'ADU': 'Swordfish and its Derivatives',
        'ADV': 'Tilapia and its Derivatives',
        'ADW': 'Trout and its Derivatives',
        'ANO': 'Perch and its Derivatives',
        'SN': 'Chestnuts and Their Derivatives',
        'ACP': 'Carp and its Derivatives',
        'ABN': 'Abalone and its Derivatives',
        'BAS': 'Basa and its Derivatives',
        'BLF': 'Bluefish and its Derivatives',
        'BRM': 'Bream and its Derivatives',
        'CHB': 'Chub and its Derivatives',
        'CHR': 'Char and its Derivatives',
        'CLM': 'Clam and its Derivatives',
        'COK': 'Cockle and its Derivatives',
        'CON': 'Conch and its Derivatives',
        'CSC': 'Cisco and its Derivatives',
        'EEL': 'Eel and its Derivatives',
        'LMT': 'Limpets and its Derivatives',
        'LSN': 'Land and sea snails (Escargot) and its Derivatives',
        'MAC': 'Mackerel and its Derivatives',
        'MAL': 'Marlin and its Derivatives',
        'MKF': 'Monkfish (Anglerfish, Lotte) and its Derivatives',
        'MM': 'Melatonin',
        'MSS': 'Mussels and its Derivatives',
        'OCT': 'Octopus and its Derivatives',
        'ORR': 'Orange roughy and its Derivatives',
        'OYS': 'Oysters and its Derivatives',
        'PER': 'Periwinkle and its Derivatives',
        'PLC': 'Plaice and its Derivatives',
        'POM': 'Pompano and its Derivatives',
        'PRG': 'Porgy and its Derivatives',
        'QUA': 'Quahaugs and its Derivatives',
        'ROF': 'Rockfish and its Derivatives',
        'SAR': 'Sardine and its Derivatives',
        'SCA': 'Scallops and its Derivatives',
        'SHK': 'Shark and its Derivatives',
        'SMT': 'Smelt and its Derivatives',
        'SQU': 'Squid (Calamari) and its Derivatives',
        'STG': 'Sturgeon and its Derivatives',
        'SU': 'Salicylate',
        'TUR': 'Turbot and its Derivatives',
        'WHK': 'Whelks and its Derivatives',
        'WHT': 'Whiting and its Derivatives',
        'AWF': 'Whitefish and its Derivatives'
    };

    const removeRecursive = (obj: any, props: any) => {
        delete props.slice(0, -1).reduce((init, curr) => init && init[curr], obj)[[...props].pop()];
    }

    if(item_json['productType'] && item_json['productType'] !== 'EA') {
        delete fieldSections['nutrients']
        delete fieldSections['ingredients']
        delete fieldSections['allergens']
        delete fieldSections['marketing']
        delete fieldSections['digitalAssets']
        delete fieldSections['healthcare']
    }
    

    const displayNutritionalDetail = (detail) => {
        let nutrientStructure = buildNutrients(detail)

        let lineStyle = { borderBottom: "1px solid #bbb"}

        const nutrientCodes = 
        {
            'CHOAVL':   'Total Carbohydrate',
            'FIBTG' :   'Dietary Fiber',
            'FAT'   :   'Total Fat',
            'PRO-'  :   'Total Protein',
            'FASAT' :   'Saturated Fat',
            'SUGAR-':   'Total Sugars',
            'FATRN':    'Fatty acids, total trans',
            'CHOCSM':   'Carbohydrate, total; summation',
            'CHOL-':    'Cholesterol',
            'NA':       'Sodium',
            'NACL':     'Salt',
            'ENER-':    'Energy',
            'PROTMFP':  'Protein from meat, fish and poultry',
            'ENER-KJ':  'Energy (Kj)',
            'FATNLEA':  'Total fat (by NLEA definition)',
            'FAPUCIS':  'FA, polyunsat.cis',
            'ENERPA':   'Energy % from alc.',
            'FAMSCIS':  'FA, monounsat.cis',
            'SALTEQ':   'Salt Equivalent:',
            'AAAN':     'Amino acids, total aromatic; Per quantity of nitrogen',
            '10FDHF':   '10-formyldihydrofolate',
        }

        let result = <tr><td></td>
        <td>
            <div style={{border: '1px solid black', padding: '10px 30px 30px 30px', width: '400px'}}>
            <h1 style={{fontSize: '34px'}}>Nutrition Facts</h1>
            <hr/>
            <div>SERVING SIZE 100</div>
            <div style={{width: '100%', height: '5px', background: 'black', margin: '10px 0 10px 0'}}></div>
            <div style={{fontSize: 'smaller', marginBottom: '30px'}}>Amount Per Serving</div>
            
            
            
        {
            Object.keys(nutrientStructure).map((keyFam)=>{
            let familyNutrient = getNutrient(nutrientStructure[keyFam], keyFam);
            let st = {
                margin: '4px 2px'
            }
            return  <Col>
                        {familyNutrient !== undefined ?
                                <Col style={lineStyle}>
                                    <Col>
                                        <Col style={st}>
                                            <span style={{fontWeight: 'bold'}}>{nutrientCodes[familyNutrient["nutrientTypeCode"]]} </span>
                                            <span>{Array.isArray(familyNutrient["quantityContained"])?parseFloat(familyNutrient["quantityContained"][0]).toFixed(2):parseFloat(familyNutrient["quantityContained"]).toFixed(2)} {familyNutrient["quantityContainedUom"] !== undefined?familyNutrient["quantityContainedUom"]:''}</span>
                                        </Col>
                                        
                                    </Col>
                                    <Col>
                                        {familyNutrient["dailyValueIntakePercent"] !== undefined?
                                        <span>{parseFloat(familyNutrient["dailyValueIntakePercent"]).toFixed(2)}%</span>
                                        :familyNutrient["dailyvalueIntakePercent"] !== undefined?
                                            <span>{parseFloat(familyNutrient["dailyvalueIntakePercent"]).toFixed(2)}%</span>
                                            :''
                                        }
                                    </Col>
                                </Col>
                            :
                                ''
                        }
                        {
                            nutrientStructure[keyFam].map((nut) => (
                                keyFam !== nut["nutrientTypeCode"] ?
                                        <Col style={lineStyle}>
                                            {keyFam !== 'OTHER' ? 
                                            <Col style={{...st, marginLeft: '30px'}}>
                                                <Col>
                                                    <span style={{fontWeight: 'normal'}}>{nutrientCodes[nut["nutrientTypeCode"]]} </span>
                                                    <span>{Array.isArray(nut["quantityContained"])?parseFloat(nut["quantityContained"][0]).toFixed(2):parseFloat(nut["quantityContained"]).toFixed(2)} {nut["quantityContainedUom"] !== undefined?nut["quantityContainedUom"]:''}</span>
                                                </Col>
                                            </Col>
                                            : nut["nutrientTypeCode"] === 'ENER-' ?
                                            <Col style={{...st, marginBottom: '40px'}}>
                                                <Col>
                                                    <span style={{fontWeight: 'bold'}}>Calories: </span>
                                                    <span style={{float: 'right'}}>{Array.isArray(nut["quantityContained"])?parseFloat(nut["quantityContained"][0]).toFixed(2):parseFloat(nut["quantityContained"]).toFixed(2)} {nut["quantityContainedUom"] !== undefined?nut["quantityContainedUom"]:''}</span>
                                                </Col>
                                            </Col> :
                                            <Col style={{...st}}>
                                                <Col>
                                                    <span style={{fontWeight: 'bold'}}>{nutrientCodes[nut["nutrientTypeCode"]]} </span>
                                                    <span>{Array.isArray(nut["quantityContained"])?parseFloat(nut["quantityContained"][0]).toFixed(2):parseFloat(nut["quantityContained"]).toFixed(2)} {nut["quantityContainedUom"] !== undefined?nut["quantityContainedUom"]:''}</span>
                                                </Col>
                                            </Col>
                                            }
                                            <Col>
                                                {nut["dailyValueIntakePercent"] !== undefined?
                                                    <span>{parseFloat(nut["dailyValueIntakePercent"]).toFixed(2)}%</span>
                                                :nut["dailyvalueIntakePercent"] !== undefined?
                                                    <span>{parseFloat(nut["dailyvalueIntakePercent"]).toFixed(2)}%</span>
                                                    :''
                                                }
                                            </Col>
                                        </Col>
                                    :
                                    ''
                            ))
                        }
                    </Col>
        })
        }
        </div>
        </td></tr>

        return result;
    }

    const nutrientFamily = {
        'FAT' :     ['FAT', 'FASAT', 'FATRN', 'FATNLEA'],
        'CHOAVL' :  ['CHOAVL', 'SUGAR-', 'FIBTG'],
        'PRO-' :    ['PRO-', 'PROTMFP'],
    }

    const getNutrient = (list, code) => {
        let nutrient = undefined;
        let list_code = '';
        list.map((n) => {
            list_code = Array.isArray(n['nutrientTypeCode']) ? n['nutrientTypeCode'][0] : n['nutrientTypeCode'];
            if(n['nutrientTypeCode'] == code) {
                nutrient = n;
            }
        });
        return nutrient;
    }

    const getNutrientFamily = (code) => {
        let family = 'OTHER';
        Object.keys(nutrientFamily).map((key) => {
            if(nutrientFamily[key].includes(code)) {
                family = key;
            }
        });

        return family;
    }

    const buildNutrients = (arrayNutrients) => {
        console.log(arrayNutrients)
        let nutrientStructure = {};
        if(Array.isArray(arrayNutrients)) {
            arrayNutrients.map((value) => {
                let code = Array.isArray(value['nutrientTypeCode']) ? value['nutrientTypeCode'][0] : value['nutrientTypeCode'];
                let family = getNutrientFamily(code);
                if(nutrientStructure[family] === undefined) {
                    nutrientStructure[family] = [];
                }
                nutrientStructure[family].push(value);
            });
        } else {
            let code = arrayNutrients.nutrientTypeCode;
            let family = getNutrientFamily(code);
            if(nutrientStructure[family] === undefined) {
                nutrientStructure[family] = [];
            }
            nutrientStructure[family].push(arrayNutrients);
        }
        return nutrientStructure;
    }

    const healthcareCategories = [
        '10000838',
        '10000674',
        '10000460',
        '10000461',
        '10000462',
        '10000675',
        '10000463',
        '10000464',
        '10000465',
        '10000466',
        '10000841',
        '10000650',
        '10000917',
        '10000918',
        '10000649',
        '10000468',
        '10000467',
        '10000651',
        '10000500',
        '10000504',
        '10000849',
        '10000852',
        '10000847',
        '10000850',
        '10000851',
        '10000846',
        '10000923',
        '10000848',
        '10000456',
        '10000457',
        '10000912',
        '10000922',
        '10000681',
        '10000902',
        '10000921',
        '10000898',
        '10000900',
        '10000899',
        '10000901',
        '10000449',
        '10000448',
        '10000450',
        '10000908',
        '10000684',
        '10000451',
        '10000909',
        '10000910',
        '10000861',
        '10000914',
        '10000862',
        '10000860',
        '10000859',
        '10000489',
        '10000907',
        '10000685',
        '10000488',
        '10000474',
        '10000863',
        '10000864',
        '10000865',
        '10000871',
        '10000870',
        '10000866',
        '10000868',
        '10000869',
        '10000867',
        '10000682',
        '10000459',
        '10000690',
        '10006960',
        '10000683',
        '10002423',
        '10000855',
        '10000856',
        '10000857',
        '10000853',
        '10000854',
        '10000858',
        '10000458',
        '10000915',
        '10000686',
        '10000570',
        '10000881',
        '10000875',
        '10000876',
        '10000882',
        '10000883',
        '10000916',
        '10000877',
        '10000878',
        '10000879',
        '10000920',
        '10000884',
        '10000880',
        '10000525',
        '10000526',
        '10000487',
        '10000529',
        '10000639',
        '10000638',
        '10000528',
        '10000637',
        '10000527',
        '10000688',
        '10000911',
        '10000687',
        '10000689',
        '10000903',
        '10000890',
        '10000891',
        '10000888',
        '10000885',
        '10000889',
        '10000892',
        '10000893',
        '10000886',
        '10000887',
        '10000906',
        '10000905',
        '10000904',
        '10000872',
        '10000919',
        '10000874',
        '10000873',
        '10000894',
        '10000895',
        '10000897',
        '10000896',
        '10000673',
        '10000843',
        '10000455',
        '10000844',
        '10000648',
        '10000454',
        '10000453',
        '10000452',
        '10000647',
        '10005844',
        '10008118',
        '10008142',
        '10008141',
        '10008140',
        '10005845',
        '10008111',
        '10006412',
        '10000514',
    ];
    
    
    if(item_json.globalClassificationCategory && healthcareCategories.includes(item_json.globalClassificationCategory.code)) {
        fieldSections['healthcare'] = [
            'alternateItemIdentification',
            'flex/alternateClassification',
            'flex/mRICompatibilityCode',
            'flex/manufacturerDeclaredReusabilityType',
            'flex/initialManufacturerSterilisationCode',
            'flex/doesTradeItemContainLatex',
            'flex/doesTradeItemContainHumanTissue',
            'flex/brandOwnerAdditionalTradeItemIdentification',
            'flex/manufacturer',
            'flex/clinicalSize',
            'flex/productInformationDetail',
        ]
    };


    let headerStyle = {borderBottom: "10px solid #000", padding: "0 0 0.25rem", margin: "0 0 0.5rem" }
    let boxStyle = {border: "1px solid #000", width: "400px", padding: "0.5rem"}
    let subHeader = {borderBottom: "2px solid rgb(0, 0, 0)"}
    
    return (
        <>
            <Row className={styles.retailerFont}> 
                <Col span={24}>
                    <h1>{photos.length > 0 ? <img src={!(photos[0].file.startsWith('https://media.trustedsource.co.za') || photos[0].file.startsWith('https://api.producthub')) ? 'https://media.trustedsource.co.za/medium?url=' + photos[0].file : photos[0].file} style={{width: '50px', verticalAlign: 'middle', maxHeight: '50px', marginRight: '5px', borderRadius: '8px'}}/> : ''}{item_json['gtinName']} 
                        <span style={{float: 'right'}}>
                            <ProductHierarchy items={hierarchyDescriptors} big={true} onClick={handleHierarcyElementClick} selectedGtin={formData.gtin}/>
                            <button className={styles.pdfButton} onClick={handlePdfClick}>PDF</button>
                        </span>
                    </h1>
                </Col>
                <Col span={12} className={styles.wrapper}>
                    <table cellSpacing={0}>
                        {
                            Object.keys(fieldSections).map((element: any, key: any) => {

                                let added = 0;
                                let process = true;
                                let row = fieldSections[element].map((objElement: any, objKey: any) => {
                                    // objElement may be like: flex/whatever/andever
                                    // We need to treat this as an array ['flex']['whatever']['andever']
                                    var names = objElement.split('/')
                                    
                                    let value = null;
                                    let json = item_json;
                                    let found = true;

                                    for(var i in names) {
                                        if(json[names[i]] !== undefined) {
                                            value = json[names[i]]
                                            json = value
                                        } else {
                                            found = false;
                                        }
                                    }
                                    
                                    var cl = `${styles.td}` + (objKey % 2 !== 0 ? ` ${styles.odd}` : '')
                                    var ret = <></>
                                    if(typeof value === 'string' || typeof value === 'number') {
                                        ret = <tr>
                                            <td className={cl}><strong>{renderName(objElement)}</strong></td>
                                            <td className={cl}>{value}</td>
                                        </tr>
                                        added++
                                    } else {
                                        if(value !== undefined) {
                                            if(element === 'allergens' && value) {
                                                names.push('allergen')
                                                value = value[0]
                                            }
                                            
        // 'packaging' : [
        //     'flex/packagingInformation',
        //     'flex/packagingDate'
        // ],
        // 'marketing' : [
        //     'flex/marketingMessage',
        //     'flex/tradeItemKeyWords',
        //     'flex/tradeItemFeatureBenefit'
        // ],
        // 'ingredients' : [
        //     'flex/ingredientStatement'
        // ],
        // 'nutrients' : [
        //     'flex/nutrientInformation'
        // ],
        // 'allergens' : [
        //     'flex/allergenRelatedInformation'
        // ]
                                            if(item_json['flex'] !== undefined) {
                                                switch(element) {
                                                    case 'packaging':
                                                        process = typeof item_json['flex']['packagingInformation'] !== 'undefined' || typeof item_json['flex']['packagingDate'] !== 'undefined'
                                                    break;
                                                    case 'marketing':
                                                        process = 
                                                            typeof item_json['flex']['marketingMessage'] !== 'undefined' || 
                                                            typeof item_json['flex']['tradeItemKeyWords'] !== 'undefined' ||
                                                            typeof item_json['flex']['tradeItemFeatureBenefit'] !== 'undefined'
                                                    break;
                                                    case 'ingredients':
                                                        process = typeof item_json['flex']['ingredientStatement'] !== 'undefined'
                                                    break;
                                                    case 'NonFoodIngredients':
                                                        process = typeof item_json['flex']['nonfoodIngredientStatement'] !== 'undefined'
                                                    break;
                                                    case 'nutrients':
                                                        process = typeof item_json['flex']['nutrientInformation'] !== 'undefined'
                                                    break;
                                                    case 'allergens':
                                                        process = typeof item_json['flex']['allergenRelatedInformation'] !== 'undefined'
                                                    break;
                                                    case 'digitalAssets':
                                                        process = typeof item_json['flex']['externalFileLink'] !== 'undefined'
                                                    break;
                                                    case 'healthcare':
                                                        let subElement = objElement.substring(5)
                                                        process = process || typeof item_json['flex'][subElement] !== 'undefined' //typeof item_json['flex']['alternateClassification'] !== 'undefined'
                                                    break;
    
                                                }
                                                
                                                if(process) {
                                                    if(element !== 'nutrients') {
                                                        if(element !== 'healthcare' || found) {
                                                            if(element === 'healthcare' && names[names.length-1] === 'alternateItemIdentification') {
                                                                ret = renderItemLine2(names[names.length-1], '', item_json, false)
                                                            } else {
                                                                ret = renderItemLine2(names[names.length-1], '', value, false)
                                                            }
                                                            
                                                        }
                                                    } else {
                                                        ret = <>{displayNutritionalDetail(value[0]['nutrientDetail'])}
                                                        <tr>
                                                            <td colSpan={2} style={{padding: '20px 0px', borderBottom: '1px solid black'}}>RAW NUTRITIONAL DATA:</td>
                                                        </tr>
                                                        {renderItemLine2(names[names.length-1], '', value, false)}</>
                                                    }
                                                    
                                                }
                                                    
                                                if(ret !== null) {
                                                    added++
                                                }
                                            }
                                            
                                        }
                                    }
                                    delete item_json_copy[item_json_copy.indexOf(objElement)]

                                    return ret
                                })

                                return process && row[0] !== null ? (<>
                                    <tr>
                                        <td><h2 style={{}}>{element}</h2></td>
                                        <td></td>
                                    </tr>
                                    {
                                        row
                                    }
                                </>) : ''
                            })
                        }   
                        <tr>
                            <td><h2>Other</h2></td><td></td>
                        </tr> {console.log("Starts OTHER")}
                        {
                            item_json_copy.map((objElement: any, objKey: any) => {
                                var names = objElement.split('/')
                                objElement = names[names.length - 1]
                                var cl = `${styles.td}` + (objKey % 2 !== 0 ? ` ${styles.odd}` : '')
                                
                                return renderItemLine2(objElement, '', names.length > 1 ? item_json[names[0]] : item_json)
                            })
                        }
                                
                        
                    </table>
                    {
                        //renderItem(item_json, '') 
                    }
                </Col>
                
                <Col span={11} offset={1} className={styles.wrapper}>
                    <Row>
                        <h2 style={{fontSize: '20px', marginTop: '30px', marginBottom: '30px'}}>Digital Assets</h2>
                    </Row> 
                    { photos.map((item, key) => {
                        return <Row style={{alignSelf: 'center'}}>
                            <Col span={12}>
                                <img src={!(item.file.startsWith('https://media.trustedsource.co.za') || item.file.startsWith('https://api.producthub')) ? 'https://media.trustedsource.co.za/medium?url=' + item.file : item.file} key={key} style={{width: '100%', borderRadius: '10px', marginBottom: '10px'}}/>
                            </Col>
                            <Col span={12} style={{paddingLeft: '10px', alignSelf: 'center'}}>
                                {item.item_draft_file_forms.map((file_form, key) => {
                                    return <><Row><strong style={{fontWeight: 'bold'}}>{file_form.item_field.name}</strong></Row>
                                    <Row style={{marginBottom: '10px', fontSize: '12px'}}>{file_form.value}</Row></>
                                })}
                            </Col>
                        </Row>
                    })}
                    {photos.length === 0 ? <div style={{textAlign: 'center'}}><img src={noImage} /><br/><span>No assets</span></div> : ''}
                </Col>
            </Row>
        </>
    );
};

//feature/multi-site-and-retailer